<template>
  <v-container fluid fill-height class="pa-0 ma-0 narekomu-back" >
    <div class="login-back people"></div>

    <v-card v-if="isError" id="login-card" class="elevation-1   login-card  body-2" 
      color="blue-grey darken-4"
      dark
    >
    <DialogError
      text="ユーザー登録に失敗しました。リンクが間違っている,またはすでに本登録が済んでいる可能性があります。"
      toTop="true"
      isReload="false"
    />
  
    </v-card>

    <v-card v-else-if="!isComplete" id="login-card" class="elevation-1   login-card  body-2" 
            color="blue-grey darken-4"
            dark
          >
          <v-card-text>
            作成中...
            <br>

            <v-progress-linear
              indeterminate
              color="white"
              
            ></v-progress-linear>
          </v-card-text>
     
    </v-card>
 
     <v-card v-else class="login-card" > 
      <DialogComplete
        text="ユーザー作成が完了しました。"
        toTop="true"
      />
    </v-card>

   </v-container>
</template>



<script>
import axios from "axios"

const DialogError = () => import('@/components/DialogError.vue');
const DialogComplete = () => import('@/components/DialogComplete.vue');

// import store from "../store"
export default {
  components: { DialogComplete,DialogError },
    name: 'CreateAccountConfirm',
  data: () => ({
    loading: false,
    isComplete:false,
    isError:false,
    token:null,
    sign:null,
  }),

  created(){
    this.createAccountConfirm();
  },

  methods: {

    //アカウントの本登録
    createAccountConfirm () {  
      this.loading = true
      axios({
          method: "PUT",
          url:`${process.env.VUE_APP_URL}account`,
          headers: {"X-Api-Key": process.env.VUE_APP_KEY, "Content-Type":"application/json" },
          data:{ token:this.$route.query.token, sign:this.$route.query.sign},
      }).then(res => {
          if(res.data.results=="complete"){
            this.loading = false;
            this.isComplete = true;
          }else {
            this.loading = false;
            this.isError = true;
          }
       
      }).catch(() => {
          this.loading = false;
          this.isError = true;
          // console.log(err.response.data)
      })
    }
  }
}
</script>

<style  scoped>
    .login-card {
        width:650px;
        margin:-50px auto 0 auto;
        z-index:2000;
    }

    .narekomu-back{
        /* background: url("../assets/login-narekomuvr.jpg"); */
        background-size: cover;
        background-position: center center;
        width: 100%;
    }

    #no-background-hover::before {
      background-color: transparent !important;
    }

.login-back{
      position:absolute;
       left: 0;
      right: 0;
      margin: auto;
      width:100%;
      max-width:1400px;
      height:100%;
    }

    .people{
      animation: fadein 0.4s ease-out forwards;
      background: url("~@/assets/people.webp") no-repeat center center;
      background-size:auto 100%;
    }

    .male{
      position:absolute;
  
      right:0%;
      height:100%;
      max-width:850px;
      min-width:100px;
      bottom:0;
      z-index:1000;
      object-fit:cover;
      object-position:right top;
    }

    .female{
      position:absolute;
      height:100%;
      left:0%;
      bottom:0;
      z-index:900;
      max-width:850px;
      min-width:100px;
      object-fit:cover;
      object-position:left top;
    }


    @media screen and (max-width: 1000px) {
      .male{
         position:absolute;
        height:100%;
        left:0;
        max-width:850px;
        min-width:100px;
        width:50%;
        bottom:0;
        z-index:1000;
        object-fit:cover;
        object-position:50% top;
      }

      .female{
        position:absolute;
        height:100%;
        left:50%;
        bottom:0;
       
        z-index:900;
        max-width:850px;
        min-width:100px;
        width:60%;
        object-fit:cover;
        object-position:50% top;
      } 

    }

</style>